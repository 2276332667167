import { Inject, Injectable } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalStorage } from '@x/common/storage';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MENU_ITEMS, MenuItem, MenuItemNode } from './menu-item';

const MENU_STATE_KEY = 'menu_state_v2';

export interface IMenuServiceState {
  open?: boolean;
  pinnedItems?: string[];
  expandedItems?: string[];
  showHidden?: boolean;
  drawerMode?: MatDrawerMode;
}

const DEFAULT_STATE: IMenuServiceState = {
  open: false,
  pinnedItems: [],
  expandedItems: [],
  showHidden: false,
  drawerMode: 'over',
};

@Injectable({ providedIn: 'root' })
export class MenuService {
  sidenav: MatSidenav | null = null;
  stateChanges = new Subject<void>();

  state: IMenuServiceState = DEFAULT_STATE;

  private items: MenuItem[] = [];
  private itemIds = new Set<string>();

  private permissions: string[] | null = null;
  private _sortByPosition = (a: MenuItem, b: MenuItem) => {
    return (a.position ?? Number.MAX_SAFE_INTEGER) - (b.position ?? Number.MAX_SAFE_INTEGER);
  };
  private _sortByParent = (a: any, b: any) => {
    return a.parentId < b.parentId ? -1 : a.parentId > b.parentId ? 1 : 0;
  };

  constructor(
    @Inject(MENU_ITEMS)
    configuredItems: MenuItem[][],
    private localStorage: LocalStorage,
    private router: Router,
  ) {
    configuredItems
      .flat()
      .sort(this._sortByParent)
      .forEach((item) => {
        if (this.itemIds.has(item.id)) {
          console.warn('MenuService: Duplicate menu item id', item.id, item);
        } else if (item.parentId) {
          if (this.itemIds.has(item.parentId)) {
            console.warn('MenuService: Unregistered parent menu item id', item.parentId, item);
            item.parentId = undefined;
          }
          this.items.push(item);
        } else {
          this.items.push(item);
        }
      });

    this.restoreState();

    router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        this.stateChanges.next();
      }
      if (event instanceof NavigationEnd) {
        if (this.state.open) {
          this.close();
        }
        this.stateChanges.next();
      }
    });

    this.stateChanges.pipe(debounceTime(1000)).subscribe(() => {
      this.saveState();
    });
  }

  registerSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;

    if (this.state.open) {
      sidenav.open();
    } else {
      sidenav.close();
    }

    sidenav.openedChange.subscribe((isOpen) => {
      console.log('open change', isOpen);
      this.state.open = isOpen;
      this.stateChanges.next();
    });
  }

  getItem(id: string) {
    return this.items.find((item) => item.id === id);
  }

  getItems(): MenuItem[] {
    return this.items;
  }

  addItem(item: MenuItem) {
    this.items = [...this.items, item];
    this.stateChanges.next();
  }

  pinItem(item: MenuItem) {
    this.state.pinnedItems = Array.from(new Set([...(this.state.pinnedItems ?? []), item.id]));
    this.stateChanges.next();
  }

  unpinItem(item: MenuItem) {
    this.state.pinnedItems = this.state.pinnedItems?.filter((id) => id !== item.id);
    this.stateChanges.next();
  }

  isPinned(item: MenuItem): boolean {
    return this.state.pinnedItems?.some((id) => id === item.id) ?? false;
  }

  expandItem(item: MenuItem) {
    this.state.expandedItems = Array.from(new Set([...(this.state.expandedItems ?? []), item.id]));
    this.stateChanges.next();
  }

  contractItem(item: MenuItem) {
    this.state.expandedItems = this.state.expandedItems?.filter((id) => id !== item.id);
    this.stateChanges.next();
  }

  isExpanded(item: MenuItem) {
    return this.state.expandedItems?.some((id) => id === item.id) ?? false;
  }

  toggleItem(item: MenuItem) {
    this.isExpanded(item) ? this.contractItem(item) : this.expandItem(item);
  }

  togglePinItem(item: MenuItem) {
    this.isPinned(item) ? this.unpinItem(item) : this.pinItem(item);
  }

  observeTree(): Observable<MenuItemNode[]> {
    return this.stateChanges.pipe(
      startWith(null),
      map(() => this.buildTree()),
    );
  }

  observeState(): Observable<IMenuServiceState> {
    return this.stateChanges.pipe(
      startWith(null),
      map(() => this.state),
    );
  }

  observePinned(): Observable<MenuItemNode[]> {
    return this.stateChanges.pipe(
      startWith(null),
      map(() => this.buildPinnedTree()),
    );
  }

  close() {
    this.sidenav?.close();
  }

  open() {
    this.sidenav?.open();
  }

  saveState() {
    this.localStorage.setItem(MENU_STATE_KEY, this.state);
  }

  restoreState() {
    this.state = this.localStorage.getItem(MENU_STATE_KEY) ?? DEFAULT_STATE;
    this.stateChanges.next();
  }

  setPermissions(permissions: string[]) {
    this.permissions = permissions;
    this.stateChanges.next();
  }

  clearPermissions() {
    this.permissions = null;
    this.stateChanges.next();
  }

  toggleShowHidden() {
    this.state.showHidden = !this.state.showHidden;
    this.stateChanges.next();
  }

  setDrawerMode(mode: MatDrawerMode) {
    this.state.drawerMode = mode;
    this.stateChanges.next();
  }

  private buildTree(): MenuItemNode[] {
    return this.getChildNodes().filter((parents) => parents.children.length > 0);
  }

  private buildPinnedTree(): MenuItemNode[] {
    return (
      this.state.pinnedItems
        ?.map((id) => {
          const item = this.items.find((s) => s.id === id);
          if (!item) return null;
          const active = this.getItemActiveState(item);
          const hidden = this.getItemHiddenState(item);
          const node: MenuItemNode = {
            ...item,
            children: [],
            active,
            hidden,
            expanded: this.isExpanded(item),
            pinned: this.isPinned(item),
          };

          return node;
        })
        .filter((n): n is MenuItemNode => !!n) ?? []
    );
  }

  private getChildNodes(parentId?: string): MenuItemNode[] {
    return this.items
      .filter((item) => (parentId ? item.parentId === parentId : !item.parentId))
      .map((item) => {
        const children = this.getChildNodes(item.id);
        const active = this.getItemActiveState(item) || children.some((item) => item.active);
        const hidden = this.getItemHiddenState(item);

        const node: MenuItemNode = {
          ...item,
          children,
          active,
          hidden,
          expanded: this.isExpanded(item),
          pinned: this.isPinned(item),
        };

        return node;
      })
      .filter((node) => this.state.showHidden || !node.hidden)
      .sort(this._sortByPosition);
  }

  private getItemActiveState(item: MenuItem): boolean {
    let itemActive = item.route
      ? this.router.isActive(item.route, {
          paths: 'exact',
          queryParams: 'ignored',
          fragment: 'ignored',
          matrixParams: 'subset',
        })
      : false;

    return itemActive;
  }

  private getItemHiddenState(item: MenuItem): boolean {
    let hasPermissions = true;

    if (!this.permissions) {
      hasPermissions = false;
    } else if (item.permissions?.length) {
      hasPermissions = item.permissions.some((p) => this.permissions?.includes(p));
    }

    return !hasPermissions;
  }
}
